body {
    font-family: Helvetica, sans-serif;
    font-style: normal;
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    color: #6E747A;
    font-family: Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #E1E5EB;
    border-radius: 4px;
  }

  .react-autosuggest__input:focus {
    border: 1px solid #167AF6;
    outline: none;
  }

  .react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #E1E5EB;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    font-style: normal;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion:not(:first-child) {
    border-top: 1px solid #ddd;
  }

  .react-autosuggest__suggestion--focused {
    background-color: #16f64e;
    color: #fff;
  }

  .suggestion-content {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
  }

  .name {
    line-height: 45px;
  }

  .highlight {
    color: #167AF6;
    font-weight: bold;
  }

  .react-autosuggest__suggestion--focused .highlight {
    color: #e40101;
  }
